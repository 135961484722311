import $ from 'jquery';

export default function subnav(
    selector = '[data-toggle-submenu]',
    menuItemSelector = '[data-menu-item]',
) {
    const items = $(selector);

    function toggleSubmenu(el, menuItem) {
        // toggle the inPageNav__active class on the menuItem
        menuItem.toggleClass('inPageNav__active');
        if (menuItem.hasClass('inPageNav__active')) {
            el.text('-');
        } else {
            el.text('+');
        }
    }

    items.each((_, element) => {
        const el = $(element);
        const menuItem = el.closest(menuItemSelector);
        const submenu = menuItem.find('[data-submenu]');
        if (!submenu.length) return;
        const submenuHeight = submenu[0].scrollHeight;
        submenu.css('--height', `${submenuHeight}px`);
        el.on('click', function(e) {
            e.stopPropagation();
            toggleSubmenu(el, menuItem);
        });
    });
}